<template>
  <div class="survey-container">
    <div class="wrapper">
      <div class="flex-align" style="gap: 10px" :style="!isMobile ? 'margin-bottom: 40px' : ''">
        <div class="progress" style="flex:1">
          <div :style="progress"></div>
        </div>
        <div v-if="!isMobile" class="primary body6">{{ index + 1 }} / 2 단계</div>
      </div>
      <!--  설문 -->
      <template v-if="index < 1">
        <div class="h7 main margin-bottom-40">{{ form.title }}</div>
        <component
          v-for="(form, idx) in form.forms"
          :key="`form-${index}-${idx}`"
          class="margin-bottom-32"
          :is="form.format"
          v-bind="form"
          :value.sync="form.value"
          @etc="(val) => onEtc(form, val)"
        ></component>

        <div class="flex-align" style="gap:8px">
          <button class="button is-gray" style="width:100px" v-if="index > 0" @click="prev">이전</button>
          <button class="button is-primary flex-1" @click="next">다음</button>
        </div>
      </template>
      <!-- 서비스 개설 정보 입력 -->
      <template v-else>
        <div class="h7 main margin-bottom-40">플랫폼 서비스의 개설 정보를 입력해주세요.</div>

        <div class="body3-medium sub2">내 서비스의 이름</div>
        <input
          class="input font-primary margin-top-8"
          type="text"
          placeholder="텍스트"
          :class="{ 'input-error': vErr.name }"
          style="width:100%"
          maxlength="30"
          v-model="order.service_name"
        />
        <!-- <div class="body5 sub4" style="margin-top:4px">*서비스 개설 후 수정할 수 있습니다</div> -->

        <div style="margin:28px 0 32px 0">
          <div class="body3-medium sub2">내 서비스 주소</div>
          <div
            class="flex-align margin-top-8 input-sub3"
            :class="{ 'input-error': vErr.key }"
            style="border-radius:8px"
          >
            <input
              class="input font-primary"
              type="text"
              placeholder="영문, 숫자"
              id="service-key"
              autocomplete="new-password"
              style="width:100%;border:0"
              maxlength="30"
              @input="onKeyupServiceId"
              @blur="validKey"
              v-model="order.key"
            />
            <div style="padding:0 16px 0 0">.platformfy.com</div>
          </div>
          <div class="err" v-if="err !== ''">{{ err }}</div>

          <div class="body5 sub4" style="margin-top:4px">*서비스 주소는 개설후에 변경 불가합니다</div>
        </div>

        <div class="flex-align" style="gap:8px">
          <button class="button is-gray" style="width:100px" v-if="index > 0" @click="prev">이전</button>
          <button class="button is-primary flex-1" @click="clickOrder">무료체험 시작하기</button>
        </div>
        <div class="flex-center" style="margin-top:10px;gap:10px">
          <div class="flex-align" style="gap:4px">
            <img src="/static/icon/u_check.svg" class="svg-primary" />
            <div class="size-14 sub">신용카드 등록없이 무료체험</div>
          </div>
          <div class="flex-align" style="gap:4px">
            <img src="/static/icon/u_check.svg" class="svg-primary" />
            <div class="size-14 sub">3분만에 개설</div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import CInputText from '../module/CInputText';
import CInputCheckboxFullGroup from '../module/CInputCheckboxFullGroup';
import CInputRadiobuttonGroup from '../module/CInputRadiobuttonGroup';
import UserAPIMixin from '../../mixins/UserAPIMixin';
import CInputDropdown from '../module/CInputDropdown';
import CInputCheckboxGroup from '../module/CInputCheckboxGroup';
export default {
  name: 'ServiceSurvey',
  mixins: [UserAPIMixin],
  components: { CInputRadiobuttonGroup, CInputCheckboxFullGroup, CInputText, CInputDropdown, CInputCheckboxGroup },
  created() {
    if (this.$store.getters.basket === undefined || Object.keys(this.$store.getters.basket).length === 0) {
      this.$router.push('/home');
      return;
    }

    if (this.$store.getters.survey) {
      let survey = this.$store.getters.survey;
      if (survey.date === this.dayjs().format('YYYY-MM-DD')) {
        this.steps = survey.steps ? this.cloneItem(survey.steps) : this.steps;
      } else {
        this.$store.commit('setSurvey', undefined);
      }
    }
    if (this.$route.query.step) {
      this.index = Number(this.$route.query.step);
    }
  },
  computed: {
    form() {
      return this.steps[this.index];
    },
    progress() {
      return `width: ${(100 * ((this.index + 1) / 2)).toFixed(2)}%;height:100%;background-color:#ff6600`;
    },
  },
  data() {
    return {
      index: 0,
      steps: [
        // {
        //   forms: [
        //     {
        //       range: { max: { val: 5, label: '' }, min: { val: 1, label: '' } },
        //       button_type: false,
        //       placeholder: '',
        //       enable_option: false,
        //       valid_required: true,
        //       format: 'CInputRadiobuttonGroup',
        //       etc: '',
        //       name: '회원님이 현재 속한 업계는 어디이신가요?',
        //       field: '',
        //       label: '회원님이 현재 속한 업계는 어디이신가요?',
        //       enable: true,
        //       validate: {
        //         required: true,
        //         valid_required: true,
        //         num: true,
        //         alpha_g: true,
        //         alpha_s: true,
        //         special: true,
        //         max_length: 100,
        //         min_length: 1,
        //       },
        //       value: '',
        //       options: [
        //         { value: '예비창업가', label: '예비창업가', uid: null, forms: [] },
        //         {
        //           value: 'IT/정보통신',
        //           label: 'IT/정보통신',
        //           uid: null,
        //           forms: [],
        //         },
        //         { value: '제조/판매/유통', label: '제조/판매/유통', uid: null, forms: [] },
        //         {
        //           value: '금융/은행/보험',
        //           label: '금융/은행/보험',
        //           uid: null,
        //           forms: [],
        //         },
        //         { value: '의료/제약', label: '의료/제약', uid: null, forms: [] },
        //         {
        //           value: '교육업',
        //           label: '교육업',
        //           uid: null,
        //           forms: [],
        //         },
        //         { value: '마케팅/광고', label: '마케팅/광고', uid: null, forms: [] },
        //         {
        //           value: '문화/예술/디자인',
        //           label: '문화/예술/디자인',
        //           uid: null,
        //           forms: [],
        //         },
        //         { value: '농업/어업/축산업', label: '농업/어업/축산업', uid: null, forms: [] },
        //         {
        //           value: '학생',
        //           label: '학생',
        //           uid: null,
        //           forms: [],
        //         },
        //         { value: '기타', label: '기타', uid: null, forms: [] },
        //       ],
        //     },
        //     {
        //       range: { max: { val: 5, label: '' }, min: { val: 1, label: '' } },
        //       button_type: false,
        //       placeholder: '',
        //       enable_option: false,
        //       valid_required: true,
        //       format: 'CInputRadiobuttonGroup',
        //       etc: '',
        //       name: '회원님의 현재 직무는 어떻게 되시나요?',
        //       field: '',
        //       label: '회원님의 현재 직무는 어떻게 되시나요?',
        //       enable: true,
        //       validate: {
        //         required: true,
        //         valid_required: true,
        //         num: true,
        //         alpha_g: true,
        //         alpha_s: true,
        //         special: true,
        //         max_length: 100,
        //         min_length: 1,
        //       },
        //       value: '',
        //       options: [
        //         { value: '예비창업가', label: '예비창업가', uid: null, forms: [] },
        //         {
        //           value: '기획/운영',
        //           label: '기획/운영',
        //           uid: null,
        //           forms: [],
        //         },
        //         { value: 'IT/인터넷', label: 'IT/인터넷', uid: null, forms: [] },
        //         {
        //           value: '디자인/미디어',
        //           label: '디자인/미디어',
        //           uid: null,
        //           forms: [],
        //         },
        //         { value: '무역/유통', label: '무역/유통', uid: null, forms: [] },
        //         {
        //           value: '영업/고객상담',
        //           label: '영업/고객상담',
        //           uid: null,
        //           forms: [],
        //         },
        //         { value: '연구/개발', label: '연구/개발', uid: null, forms: [] },
        //         {
        //           value: '생산/제조',
        //           label: '생산/제조',
        //           uid: null,
        //           forms: [],
        //         },
        //         { value: '학생', label: '학생', uid: null, forms: [] },
        //       ],
        //     },
        //     {
        //       range: { max: { val: 5, label: '' }, min: { val: 1, label: '' } },
        //       button_type: false,
        //       placeholder: '',
        //       enable_option: false,
        //       valid_required: true,
        //       format: 'CInputRadiobuttonGroup',
        //       etc: '',
        //       name: '회원님의 현재 직급은 어떻게 되시나요?',
        //       field: '',
        //       label: '회원님의 현재 직급은 어떻게 되시나요?',
        //       enable: true,
        //       validate: {
        //         required: true,
        //         valid_required: true,
        //         num: true,
        //         alpha_g: true,
        //         alpha_s: true,
        //         special: true,
        //         max_length: 100,
        //         min_length: 1,
        //       },
        //       value: '',
        //       options: [
        //         { value: '예비창업가', label: '예비창업가', uid: null, forms: [] },
        //         {
        //           value: '대표',
        //           label: '대표',
        //           uid: null,
        //           forms: [],
        //         },
        //         { value: '임원', label: '임원', uid: null, forms: [] },
        //         {
        //           value: '중간관리자',
        //           label: '중간관리자',
        //           uid: null,
        //           forms: [],
        //         },
        //         { value: '사원', label: '사원', uid: null, forms: [] },
        //         {
        //           value: '학생',
        //           label: '학생',
        //           uid: null,
        //           forms: [],
        //         },
        //       ],
        //     },
        //   ],
        //   title: '회원님의 현재 커리어를 알려주세요.',
        // },
        {
          forms: [
            {
              range: { max: { val: 5, label: '' }, min: { val: 1, label: '' } },
              button_type: false,
              placeholder: '입력해주세요.',
              enable_option: false,
              valid_required: true,
              format: 'CInputText',
              name: '제작하고자 하는 업계/업종',
              field: '',
              label: '어떤 업계/업종을 대상으로 플랫폼을 제작하고자 하시나요?',
              enable: true,
              value: '',
              validate: {
                required: true,
                valid_required: true,
                num: true,
                alpha_g: true,
                alpha_s: true,
                special: true,
                max_length: 100,
                min_length: 1,
              },
            },
            {
              range: { max: { val: 5, label: '' }, min: { val: 1, label: '' } },
              button_type: false,
              placeholder: '선택해주세요.',
              enable_option: false,
              valid_required: true,
              format: 'CInputDropdown',
              etc: '',
              name: '비즈니스는 어디까지 진행되셨나요?',
              field: '',
              label: '비즈니스는 어디까지 진행되셨나요?',
              enable: true,
              validate: {
                required: true,
                valid_required: true,
                num: true,
                alpha_g: true,
                alpha_s: true,
                special: true,
                max_length: 100,
                min_length: 1,
              },
              value: '',
              options: [
                {
                  value: '현재 아이디어 구상 중입니다.',
                  label: '현재 아이디어 구상 중입니다.',
                  uid: null,
                  forms: [],
                },
                {
                  value: '아이디어를 바탕으로 팀빌딩 중입니다',
                  label: '아이디어를 바탕으로 팀빌딩 중입니다',
                  uid: null,
                  forms: [],
                },
                {
                  value: '서비스 구축 작업 중입니다',
                  label: '서비스 구축 작업 중입니다',
                  uid: null,
                  forms: [],
                },
                {
                  value: '시장 검증 진행 중입니다',
                  label: '시장 검증 진행 중입니다',
                  uid: null,
                  forms: [],
                },
                {
                  value: '이미 비지니스를 운영하고있고, 온라인으로 사업을 확장하고있습니다',
                  label: '이미 비지니스를 운영하고있고, 온라인으로 사업을 확장하고있습니다',
                  uid: null,
                  forms: [],
                },
                {
                  value: '서비스에 호기심이 생겨,테스트 차원으로 이용하고자 합니다',
                  label: '서비스에 호기심이 생겨,테스트 차원으로 이용하고자 합니다',
                  uid: null,
                  forms: [],
                },
                { value: '기타', label: '기타', uid: null, forms: [] },
              ],
            },
            // {
            //   range: { max: { val: 5, label: '' }, min: { val: 1, label: '' } },
            //   button_type: false,
            //   placeholder: '선택해주세요.',
            //   enable_option: false,
            //   valid_required: true,
            //   format: 'CInputDropdown',
            //   etc: '',
            //   name: '플랫폼 비지니스를 함께 하는 총 인원이 얼마나 되시나요?',
            //   field: '',
            //   label: '플랫폼 비지니스를 함께 하는 총 인원이 얼마나 되시나요?',
            //   enable: true,
            //   validate: {
            //     required: true,
            //     valid_required: true,
            //     num: true,
            //     alpha_g: true,
            //     alpha_s: true,
            //     special: true,
            //     max_length: 100,
            //     min_length: 1,
            //   },
            //   value: '',
            //   options: [
            //     { value: '대표 1인', label: '대표 1인', uid: null, forms: [] },
            //     {
            //       value: '2인',
            //       label: '2인',
            //       uid: null,
            //       forms: [],
            //     },
            //     { value: '3~4인', label: '3~4인', uid: null, forms: [] },
            //     {
            //       value: '5인 이상',
            //       label: '5인 이상',
            //       uid: null,
            //       forms: [],
            //     },
            //   ],
            // },
            {
              range: { max: { val: 5, label: '' }, min: { val: 1, label: '' } },
              button_type: true,
              placeholder: '',
              enable_option: false,
              valid_required: true,
              format: 'CInputCheckboxFullGroup',
              name: '팀원 구성은 어떻게 되어 있나요? (모두 선택해주세요)',
              field: '',
              label: '팀원 구성은 어떻게 되어 있나요? (모두 선택해주세요)',
              enable: true,
              validate: {
                required: true,
                valid_required: true,
                num: true,
                alpha_g: true,
                alpha_s: true,
                special: true,
                max_length: 100,
                min_length: 1,
              },
              value: '',
              options: [
                { value: '기획', label: '기획', uid: null, forms: [] },
                {
                  value: '디자인',
                  label: '디자인',
                  uid: null,
                  forms: [],
                },
                { value: '개발', label: '개발', uid: null, forms: [] },
                {
                  value: '영업',
                  label: '영업',
                  uid: null,
                  forms: [],
                },
              ],
            },
            // {
            //   range: { max: { val: 5, label: '' }, min: { val: 1, label: '' } },
            //   button_type: true,
            //   placeholder: '',
            //   enable_option: false,
            //   valid_required: true,
            //   format: 'CInputCheckboxGroup',
            //   name: '전문가의 도움이 필요하신가요?',
            //   field: '',
            //   label: '전문가의 도움이 필요하신가요?',
            //   enable: true,
            //   validate: {
            //     required: true,
            //     valid_required: true,
            //     num: true,
            //     alpha_g: true,
            //     alpha_s: true,
            //     special: true,
            //     max_length: 100,
            //     min_length: 1,
            //   },
            //   value: '',
            //   options: [
            //     { value: '네', label: '네', uid: null, forms: [] },
            //     {
            //       value: '아니오',
            //       label: '아니오',
            //       uid: null,
            //       forms: [],
            //     },
            //   ],
            // },
          ],
          title: '어떤 플랫폼을 계획중이신가요?',
        },
      ],
      order: {
        service_name: '',
        key: '',
      },
      vErr: {
        name: false,
        key: false,
      },
      err: '',
    };
  },
  watch: {
    $route() {
      if (this.$route.query.step) {
        this.index = Number(this.$route.query.step);
      } else {
        this.index = 0;
      }
    },
  },
  methods: {
    onEtc(form, val) {
      form.etc = val;
    },
    prev() {
      this.index--;
      this.$router.push(`/service_survey?step=${this.index}`);
      window.scrollTo(0, 0);
    },
    next() {
      if (this.valid()) {
        this.index++;
        this.$router.push(`/service_survey?step=${this.index}`);
        window.scrollTo(0, 0);
      }
    },
    valid() {
      let result = this.form.forms.every((i) => i.value !== '');
      if (!result) {
        this.toast('문항을 모두 선택해주세요.');
      }
      return result;
    },
    async validKey() {
      this.vErr.key = false;
      this.err = '';
      let res = await this.request.user.post('ui/super_builder/check_service', { key: this.order.key });
      if (res.data.result) {
        this.vErr.key = true;
        this.err = '다른 주소를 입력해주세요.';
      }
    },
    onKeyupServiceId: function(e) {
      // if(/[0-9a-z]/.test(e.target.value[e.target.value.length-1])) {
      //   this.params.service_id = e.target.value;
      // }

      // eslint-disable-next-line no-useless-escape
      let regExp = /[\{\}\[\]\/?_.,;:|\)*~`!^\-+<>@\#$%&\\\=\(\'\"]/gi;
      // 특수문자 예외처리
      if (regExp.test(e.target.value)) {
        this.order.key = e.target.value.replace(regExp, '');
      } else if (
        e.target.value.length > 0 &&
        /[a-z]/.test(e.target.value[0]) &&
        /[0-9a-z]/.test(e.target.value[e.target.value.length - 1])
      ) {
        this.order.key = e.target.value;
      } else {
        this.order.key = '';
      }
      this.order.key = this.order.key.toLowerCase();
    },
    async clickOrder() {
      await this.validKey();
      if (this.order.service_name === '') {
        this.toast('내 서비스 이름을 먼저 입력해주세요');
        this.vErr.name = true;
        return;
      } else if (this.order.key === '') {
        this.toast('내 서비스 주소를 입력해주세요');
        this.vErr.key = true;
        return;
      } else if (this.vErr.key) {
        this.toast('다른 서비스 주소를 입력해주세요.');
        return;
      }

      let params = this.$store.getters.basket;
      Object.assign(params, this.order);

      let form = this.steps.map((step) => {
        let result = {
          title: step.title,
          forms: step.forms.map((form) => {
            let answer = form.value;
            if (Array.isArray(answer)) {
              answer = answer.toString();
            } else if (answer === '기타') {
              answer = form.etc;
            }
            return {
              question: form.label,
              answer: answer,
            };
          }),
        };
        return result;
      });

      this.request.user.post('launchpack/v1/theme/order_new', { order: params, form: form }).then((res) => {
        if (res.status === 200) {
          //this.$router.replace('/mypage/my_service');
        }
      });
      this.$store.commit('setSurvey', {
        date: this.dayjs().format('YYYY-MM-DD'),
        steps: this.cloneItem(this.steps),
        order: this.cloneItem(this.order),
      });
      this.$router.replace(`/processing?skey=${this.order.key}`);
    },
  },
};
</script>

<style lang="stylus" scoped>
.survey-container
  padding 94px 0 80px 0
  background-color #F5F5F5
  display flex
  justify-content center
.wrapper
  background-color white
  padding 40px
  width 448px
  border-radius 4px
.progress
  background-color #eee
  width 100%
  height 6px
  border-radius 6px
  overflow hidden
.flex-1
  flex 1

.input-sub3
  border 1px solid #e3e3e3
.input-error
  border 1px solid #ff6600

.err
  color red
  font-size 11px
  margin-top 4px
  margin-left 4px

.button
  font-size 16px
  height 52px

@media (max-width:640px)
  .survey-container
    padding 0
    padding-top 60px
  .wrapper
    padding 20px
    padding-bottom 60px
  .progress
    position fixed
    z-index 10
    top 60px
    left 0
    border-radius 0
</style>
